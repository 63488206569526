// import './ol.js';
const ol = require('./ol');

export default class PackNoezon {
    config = {};
    apiUrl = 'https://pack.noezon.com/api/v1';
    isInited = false;
    elInput = null;
    elSearch = null;
    id = null;
    title = null;
    country = null;
    company = null;
    branches = [];
    position = null;
    geolocation = null;
    features = {};
    selectedFeature = null;
    mode = 'map';
    interactionSelect = null;

    constructor(config) {

    }

    init(config) {
        if(typeof config === 'object' && config !== null) {
            this.config = config;
        } else {
            this.config.element = config;
        }

        const elButton = document.querySelector(this.config.element);

        if(this.config.input) {
            const elInput = document.querySelector(this.config.input);

            if(elInput) {
                this.elInput = document.querySelector(this.config.input);
            }
        }

        if(!elButton) {
            console.error('[PACKNOEZON] Element not found!');
            return;
        }

        if(this.config.id) {
            this.id = this.config.id;
        }

        if(this.config.title) {
            this.title = this.config.title;
        }

        if(this.config.country) {
            this.country = this.config.country;
        }

        if(this.config.company) {
            this.company = this.config.company;
        }

        if(this.config.map) {
            this.fetchBranches((branches) => {
                this.branches = branches;
            });
        }

        elButton.addEventListener('click', (e) => {
            const parentElement = document.querySelector('.noepack-wrapper-' + this.id);

            document.querySelectorAll('[tabindex]').forEach((el) => { el.removeAttribute('tabindex') });
            parentElement.classList.add("show");

            if(!this.isMobile()) {
                this.elSearch.focus();
            }

            if (!this.isInited) {
                this.drawMap();
                this.isInited = true;
            }

            if(this.config.geolocation) {
                this.mapCenterByGeolocation();
            }
        });

        this.initModal();
    }

    initModal() {
        document.body.insertAdjacentHTML('beforeend',
            `<div class="noepack-wrapper noepack-wrapper-${this.id}" role="dialog">
                    <div class="noepack-modal">
                        <div class="noepack-header"><h5 class="noepack-title">${this.title}</h5><button class="noepack-close">X</button></div>
                        <div class="noepack-body">
                            <div class="noepack-list">
                                <input class="noepack-search" placeholder="Hľadať výdajné miesto"> 
                                <div class="noepack-selected">Vyberte pobocku</div>
                                <div class="noepack-branches"></div>
                            </div>
                            <div class="noepack-map" id="noepack-map-${this.id}">
                                <div class="noepack-popup"></div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>`);

        const parentElement = document.querySelector('.noepack-wrapper-' + this.id);

        // document.querySelector('.noepack-close').addEventListener('click', (e) => {
        //     this.close();
        // });

        // let elClose = parentElement.querySelectorAll('.noepack-close');
        //
        // elClose.forEach(el => {
        //     el.addEventListener('click', (e) => {
        //         this.close();
        //     });
        // });

        document.addEventListener('click', (e) => {
            if (e.target.classList.contains('noepack-close')) {
                this.ok();
            }
        });

        this.elSearch = parentElement.querySelector('.noepack-search');
        const elBranches = parentElement.querySelector('.noepack-branches');

        this.elSearch.addEventListener('keyup', this.debounce(() => {
            if (this.elSearch.value === '') {
                elBranches.textContent = '';
                return;
            }

            this.fetchBranches((branches) => {
                elBranches.textContent = '';

                branches.forEach(branch => {
                    elBranches.insertAdjacentHTML('beforeend', `<button class="noepack-branch" data-id="${branch.id}"><strong>${branch.name}</strong><br><span class="noepack-text-small">${branch.place}</div></button>`);
                });
            }, this.elSearch.value);
        }, 300));

        elBranches.addEventListener('click', (e) => {
            if(e.target.nodeName == "BUTTON") {
                let branchId = e.target.dataset.id;

                if(this.isMobile()) {
                    this.setMode('map');
                }

                this.select(branchId);
                this.iconHighlight(branchId);

                setTimeout(() => {
                    this.mapCenterByBranchId(branchId) ;

                    if(this.config.confirm === false) {
                        // this.send();
                        this.ok();
                    }
                }, 300);
            }
        });

        this.elSearch.addEventListener('keyup', () => {
            if(this.isMobile()) {
                this.setMode('list');
            }
        });
    }

    debounce(fn, delay) {
        let timeoutId;
        return function (...args) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => fn.apply(this, args), delay);
        };
    }

    setMode(mode) {
        this.mode = mode;

        if(this.mode === 'list') {
            document.querySelector('.noepack-branches').classList.remove('hide');
        } else {
            document.querySelector('.noepack-branches').classList.add('hide');
        }
    }

    toggleMode() {
        this.setMode(this.mode === 'map' ? 'list' : 'map');
    }

    getFeatureByBranchId(branchId) {
        return this.features[branchId];
    }

    iconHighlight(branchId) {
        this.selectedFeature = this.getFeatureByBranchId(branchId);
    }

    isMobile() {
        return (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4)));
    }

    mapCenterByGeolocation() {
        let options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        };

        navigator.geolocation.getCurrentPosition(this.geolocationSuccess.bind(this), this.geolocationError, options);
    }

    fetchBranches(callback, search) {
        let params = {};

        if(search && search.length > 0) {
            params.search = search;
        }

        if(this.country) {
            params['country'] = this.country;
        }

        if(this.company) {
            params['company.id'] = this.company;
        }

        let urlParams = new URLSearchParams(params).toString();

        this.makeRequest('GET', this.apiUrl + '/branches.json?' + urlParams, null, (data, error) => {
            callback(JSON.parse(data));
        });
    }

    fetchBranch(id, callback) {
        this.makeRequest('GET', this.apiUrl + '/branches/' + id + '.json', null, (data, error) => {
            callback(JSON.parse(data));
        });
    }

    drawMap() {
        document.getElementById('noepack-map-' + this.id).innerHTML = '';

        const center = ol.proj.transform([17.1055405, 48.1424178], 'EPSG:4326', 'EPSG:3857');

        this.view = new ol.View({
            center: center,
            zoom: 14,
            maxZoom: 18
        });

        let map = new ol.Map({
            layers: [
                new ol.layer.Tile({
                    source: new ol.source.OSM(),
                }) ],
            target: 'noepack-map-' + this.id,
            view: this.view,
        });

        this.fetchBranches((branches) => {
            this.addMapMarkers(map, branches);

            if(this.config.selected) {
                this.select(this.config.selected);
                // this.mapCenterByBranchId(this.config.selected);
                this.iconHighlight(this.config.selected);
                setTimeout(() => { this.mapCenterByBranchId(this.config.selected) }, 300);
            }

            if(this.elInput && this.elInput.value) {
                this.select(this.elInput.value);
                // this.mapCenterByBranchId(this.elInput.value);
                this.iconHighlight(this.elInput.value);
                setTimeout(() => { this.mapCenterByBranchId(this.elInput.value) }, 300);
            }
        });

        const parentElement = document.querySelector('.noepack-wrapper-' + this.id);
        var elPopup = parentElement.querySelector('.noepack-popup');

        var overlay = new ol.Overlay({
            element: elPopup,
            positioning: 'bottom-center',
            stopEvent: false,
            offset: [0, -25],
        });

        map.addOverlay(overlay);


        var selectedStyleFunction = function(feature, resolution) {
            return [new ol.style.Style({
                image: new ol.style.Icon({
                    size: [44, 50],
                    anchor: [0.5, 46],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: '/pack_noezon_v1/zasielkovna2.png',
                })
            })];
        };

        this.interactionSelect = new ol.interaction.Select({
            style: selectedStyleFunction
        });

        map.addInteraction(this.interactionSelect);

        map.on('pointermove', (event) => {
            var feature = map.forEachFeatureAtPixel(event.pixel, function (feature) {
                return feature;
            });

            if (feature && feature.get('features').length === 1) {
                var coordinate = event.coordinate;

                elPopup.innerHTML = feature.get('features')[0].get('branchName');
                overlay.setPosition(coordinate);
            } else {
                overlay.setPosition(undefined);
            }
        });

        map.on('click', (event) => {
            var feature = map.forEachFeatureAtPixel(event.pixel, function (feature) {
                return feature;
            });

            if (feature && feature.get('features').length === 1) {
                let branchId = feature.get('features')[0].get('branchId');
                this.select(branchId);

                // this.mapCenterByBranchId(branchId);
                this.iconHighlight(branchId);

                setTimeout(() => { this.mapCenterByBranchId(branchId) }, 300);
            } else {
                overlay.setPosition(undefined);
            }
        });

        setTimeout(() => { map.updateSize(); }, 3000);

        map.getView().on('change:resolution', function(evt){
            var view = evt.target;

            map.getLayers().getArray().map(function(layer) {
                var source = layer.getSource();
                if (source instanceof ol.source.Cluster) {
                    var distance = source.getDistance();
                    if (view.getZoom() >= 14 && distance > 0) {
                        source.setDistance(0);
                    }
                    else if (view.getZoom() < 14 && distance === 0) {
                        source.setDistance(50);
                    }
                }
            });
        }, map);

        // map.render();

        // map.invalidateSize();

        window.addEventListener('resize', () => {
            setTimeout(() => {
                map.render();
            }, 200);
        });
    }

    getBranchById(id) {
        return this.branches.find(branch => {
            return branch.id == id;
        });
    }

    mapCenterByBranchId(branchId) {
        let branch = this.branchSelected || this.getBranchById(branchId);

        // if (!branch) {
        //     this.fetchBranch(id, (branch) => {
        //         this.branchSelected = branch;
        //         const elSelected = parentElement.querySelector('.noepack-selected');
        //
        //         elSelected.innerHTML = `<div class="noepack-selected-branch">${branch.name}<br>${branch.place}</div>`;
        //
        //         if(!this.isMobile()) {
        //             elSelected.innerHTML += `<div class="noepack-selected-openinghours">${branch.openingHoursCompact.join("<br>")}</div> <div class="noepack-selected-payment">${branch.creditCardPayment ? 'Možnosť platby kartou' : 'Nie je možné platiť kartou'}</div>`;
        //         }
        //
        //         elSelected.classList.add('selected');
        //     })
        // }

        this.mapCenter(branch.address.longitude, branch.address.latitude);
    }

    mapCenterByPosition(position) {
        this.view.animate({
            center: ol.proj.transform(position, 'EPSG:4326', 'EPSG:3857'),
            duration: 200,
        });
    }

    mapCenter(lng, lat) {
        this.view.animate({
            center: ol.proj.transform([lng, lat], 'EPSG:4326', 'EPSG:3857'),
            duration: 200,
        });
    }

    getLonLat(lon, lat) {
        var fromProjection = new OpenLayers.Projection("EPSG:4326");   // Transform from WGS 1984
        var toProjection   = new OpenLayers.Projection("EPSG:900913"); // to Spherical Mercator Projection

        return new OpenLayers.LonLat(lon, lat).transform(fromProjection, toProjection);
    }

    addMapMarkers(map, branches) {
        branches.forEach((branch) => {
            if(!branch.active) {
                return;
            }

            var iconFeature = new ol.Feature({
                geometry: new ol.geom.Point(ol.proj.fromLonLat([branch.address.longitude, branch.address.latitude])),
                branchName: branch.name,
                branchId: branch.id,
            });

            var iconStyle = new ol.style.Style({
                image: new ol.style.Icon({
                    size: [44, 50],
                    anchor: [0.5, 46],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: '/pack_noezon_v1/zasielkovna.png',
                })
            });

            iconFeature.setStyle(iconStyle);

            this.features[branch.id] = iconFeature;
        });

        var source = new ol.source.Vector({
            features: Object.values(this.features)
        });

        var clusterSource = new ol.source.Cluster({
            distance: 50,
            source: source,
        });

        var styleCache = {};

        var layer = new ol.layer.Vector({
            source: clusterSource,

            style: function (feature) {
                var size = feature.get('features').length;
                var style = styleCache[size];

                if(size == 1) {
                    style = new ol.style.Style({
                        image: new ol.style.Icon({
                            size: [44, 50],
                            anchor: [0.5, 46],
                            anchorXUnits: 'fraction',
                            anchorYUnits: 'pixels',
                            src: '/pack_noezon_v1/zasielkovna.png',
                        })
                    });
                } else {
                    if (!style) {
                        style = new ol.style.Style({
                            image: new ol.style.Icon({
                                size: [39, 49],
                                anchor: [0.5, 46],
                                anchorXUnits: 'fraction',
                                anchorYUnits: 'pixels',
                                src: '/pack_noezon_v1/zasielkovna3.png',
                            }),
                            text: new ol.style.Text({
                                font: 'font: 12px Helvetica Neue,Arial,Helvetica,sans-serif;',
                                text: size.toString(),
                                fill: new ol.style.Fill({
                                    color: '#fff',
                                }),
                                scale: 1.2,
                                offsetX: 1,
                                offsetY: -25,
                            }),
                        });
                        styleCache[size] = style;
                    }
                }

                return style;
            },
        });

        map.addLayer(layer);
    }

    addMapMarker(map, branch) {
        var iconFeature = new ol.Feature({
            geometry: new ol.geom.Point(ol.proj.fromLonLat([branch.address.longitude, branch.address.latitude])),
            branchName: branch.name,
            branchId: branch.id,
        });

        this.features[branch.id] = iconFeature;

        var iconStyle = new ol.style.Style({
            image: new ol.style.Icon({
                size: [44, 50],
                anchor: [0.5, 46],
                anchorXUnits: 'fraction',
                anchorYUnits: 'pixels',
                src: '/pack_noezon_v1/zasielkovna.png',
            })
        });

        iconFeature.setStyle(iconStyle);

        var source = new ol.source.Vector({
            features: [iconFeature]
        });

        var clusterSource = new ol.source.Cluster({
            distance: parseInt(40, 10),
            source: source,
        });

        var layer = new ol.layer.Vector({
            source: clusterSource
        });
        map.addLayer(layer);
    }

    ok() {
        this.send();
        this.close();
    }

    // cancel() {
    //     this.close();
    // }

    select(id) {
        const parentElement = document.querySelector('.noepack-wrapper-' + this.id);

        this.fetchBranch(id, (branch) => {
            this.branchSelected = branch;
            const elSelected = parentElement.querySelector('.noepack-selected');

            elSelected.innerHTML = `<div class="noepack-selected-branch">${branch.name}<br>${branch.place}</div>`;

            if(!this.isMobile()) {
                elSelected.innerHTML += `<div class="noepack-selected-openinghours">${branch.openingHoursCompact.join("<br>")}</div> <div class="noepack-selected-payment">${branch.creditCardPayment ? 'Možnosť platby kartou' : 'Nie je možné platiť kartou'}</div>`;
            }

            elSelected.innerHTML += `<button class="noepack-close">Potvrdiť výber</button>`;

            elSelected.classList.add('selected');

            const elBranches = document.querySelectorAll('.noepack-branches');

            elBranches.forEach(el => {
                el.textContent = '';
            });
        })
    }

    send() {
        if(this.elInput) {
            this.elInput.value = this.branchSelected.id;
        }

        if(typeof this.config.callback === 'function') {
            this.config.callback(this.branchSelected);
        }
    }

    geolocationSuccess(position) {
        this.position = position;

        this.mapCenter(position.coords.longitude, position.coords.latitude)
    }

    geolocationError(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    close() {
        document.querySelector('.noepack-wrapper-' + this.id).classList.remove("show");
        this.elSearch.value = '';

        const elBranches = document.querySelectorAll('.noepack-branches');

        elBranches.forEach(el => {
            el.textContent = '';
        });
    }

    makeRequest(method, url, data, callback) {
        try {
            var xhr = new XMLHttpRequest();
            xhr.open(method, url);
            xhr.timeout = 10000;

            xhr.setRequestHeader('Content-type', 'application/json');

            xhr.onload = function () {
                if (this.status >= 200 && this.status < 300) {
                    callback(xhr.response, false);
                } else {
                    callback({
                        status: this.status,
                        statusText: xhr.statusText
                    }, true);
                }
            };

            xhr.onerror = function () {
                callback({
                    status: this.status,
                    statusText: xhr.statusText
                }, true);
            };

            xhr.ontimeout = function () {
                callback({
                    status: this.status,
                    statusText: xhr.statusText
                }, true);
            };

            if (method === "POST" && data) {
                xhr.send(data);
            } else if (method === "GET") {
                xhr.send();
            }
        } catch (error) {
            callback({
                error: "XMLHttpRequest error: " + error
            }, true)
        }
    }
}